import {
  Anchor,
  Box,
  Container,
  Heading,
  List,
  Rhythm,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';
import { forwardRef } from 'react';

import {
  primaryNavItems,
  secondaryNavItems,
} from '~/components/SiteNav/constants';

import Logo from '../Logo';

// Omit last link (privacy)
const linkItems = [...primaryNavItems, ...secondaryNavItems.slice(0, -1)];

const FooterAnchor = forwardRef(
  (props: React.ComponentProps<typeof Anchor>, ref) => (
    <Anchor {...props} variant="matchTextUnderlined" ref={ref} />
  ),
);

const SiteFooter = () => (
  <Box as="footer" bg="background.dark" color="white" py="5" mt="5">
    <Container display="flex" alignItems="center">
      <Rhythm between="5">
        <Logo title="Spelvärde" height={['26px', null, '34px']} />
        <Text.p fontSize="2" maxWidth="545px">
          Spelvärde.se grundades 2014 med ambitionen att erbjuda världens mest
          genomarbetade och bästa travtips. Våra starka resultat medförde att vi
          snabbt blev Sveriges ledande tipstjänst för spel på trav - en position
          vi behållit sedan dess.
        </Text.p>
        <Rhythm between="2">
          <Heading.h5>Kontakta Spelvärde</Heading.h5>
          <Text.p fontSize="2" maxWidth="545px">
            Mejla{' '}
            <FooterAnchor href="mailto:support@spelvarde.se">
              support@spelvarde.se
            </FooterAnchor>{' '}
            så svarar vi så fort vi kan – alla dagar i veckan.
          </Text.p>
        </Rhythm>
        <List
          sx={{
            li: {
              display: ['list-item', null, 'inline-block'],
            },
            'li:not(:last-child)': {
              mr: [0, null, 3],
              mb: [1, null, 0],
            },
          }}
        >
          {linkItems.map(([key, label, href]) => (
            <List.listItem key={key}>
              <Link href={href} passHref>
                <FooterAnchor fontWeight="bold" fontSize="2">
                  {label}
                </FooterAnchor>
              </Link>
            </List.listItem>
          ))}
        </List>
      </Rhythm>
    </Container>
  </Box>
);

export default SiteFooter;
