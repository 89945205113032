import { Anchor, Button, FlexBox, Text } from '@trmediaab/zebra';

import External from '~/icons/External';

interface Props {
  href: string;
  icon?: boolean;
  newTab?: boolean;
}

interface ExternalLinkProps
  extends React.ComponentProps<typeof Anchor>,
    Props {}

interface ExternalLinkButtonProps
  extends Omit<React.ComponentProps<typeof Button>, 'children'>,
    Props {
  text: string;
  iconAlignment?: 'center' | 'right';
}

const ExternalLink = ({
  href,
  icon = false,
  newTab = true,
  children,
  ...props
}: ExternalLinkProps) => (
  <Anchor
    href={href}
    target={newTab ? '_blank' : undefined}
    rel={newTab ? 'noopener noreferrer' : undefined}
    {...props}
  >
    {icon ? (
      <FlexBox alignItems="center">
        <External mr={[2, null, '2,5']} mt="1px" /> {children}
      </FlexBox>
    ) : (
      children
    )}
  </Anchor>
);

export const ExternalLinkButton = ({
  href,
  newTab = true,
  text,
  iconAlignment,
  ...props
}: ExternalLinkButtonProps) => (
  <Button
    href={href}
    target={newTab ? '_blank' : undefined}
    rel={newTab ? 'noopener noreferrer' : undefined}
    bc="tertiary"
    {...props}
  >
    <Text pr="3">{text}</Text>{' '}
    <External
      width="12px"
      ml={iconAlignment === 'right' ? 'auto' : undefined}
      mt="1px"
    />
  </Button>
);

export default ExternalLink;
