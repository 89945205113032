import { useRouter } from 'next/router';

import { selectOrigin } from '~/main/config';

import { useAppSelector } from './useRedux';
import useUser from './useUser';

export default function useAuthUrl() {
  const [user] = useUser();
  const baseUrl = useAppSelector(selectOrigin);
  const { asPath } = useRouter();

  const qs = `?ReturnUrl=${encodeURI(baseUrl + asPath)}`;

  return user != null
    ? `${process.env.NEXT_PUBLIC_AUTH_URL}/logout${qs}`
    : `${process.env.NEXT_PUBLIC_AUTH_URL}${qs}`;
}
