import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as InstagramIcon } from './instagram.svg';

const Instagram = createLocalStyledIcon(InstagramIcon, 'Instagram');

Instagram.defaultProps = {
  width: 20,
  height: 'auto',
};

export default Instagram;
