import { Box, FlexBox, Text } from '@trmediaab/zebra';
import { useClickOutside, useToggle } from '@trmediaab/zebra-hooks';
import { useCallback, useRef } from 'react';

import { SiteNavContextProvider } from '~/components/SiteNav/SiteNavContext';
import useRouterEvents from '~/hooks/useRouterEvents';

import SiteFooter from '../SiteFooter';
import SiteHeader from '../SiteHeader';
import SiteNav from '../SiteNav';

interface SiteLayoutProps {
  children: React.ReactNode;
}

const SiteLayout = ({ children }: SiteLayoutProps) => {
  const siteNavState = useToggle(false);
  const [navOpen, toggleOpen] = siteNavState;
  const siteNavRef = useRef<HTMLDivElement>(null);
  const burgerButtonRef = useRef<HTMLButtonElement>(null);

  const closeNav = useCallback(() => {
    if (navOpen) {
      toggleOpen();
    }
  }, [navOpen, toggleOpen]);

  useRouterEvents({ onComplete: closeNav });
  useClickOutside([siteNavRef, burgerButtonRef], closeNav);

  return (
    <SiteNavContextProvider value={siteNavState}>
      <Text.root
        display="flex"
        css={`
          min-height: 100vh;
          /* mobile viewport bug fix */
          min-height: -webkit-fill-available;
        `}
      >
        <SiteNav ref={siteNavRef} />
        <FlexBox flex="1" flexDirection="column">
          <SiteHeader burgerButtonRef={burgerButtonRef} />
          <Box flex="1" as="main">
            {children}
          </Box>
          <SiteFooter />
        </FlexBox>
      </Text.root>
    </SiteNavContextProvider>
  );
};

export default SiteLayout;
