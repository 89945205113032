import { Button, Text } from '@trmediaab/zebra';
import { forwardRef } from 'react';

import Account from '~/icons/Account';

interface LoggedInUserProps {
  popoverVisible: boolean;
  togglePopover: React.MouseEventHandler;
}

const LoggedInUser = forwardRef<HTMLButtonElement, LoggedInUserProps>(
  ({ popoverVisible, togglePopover }, ref) => (
    <>
      <Button
        ref={ref}
        shape="square"
        size="siteHeader"
        px={[null, null, 3, '3,5']}
        sx={{
          ...(popoverVisible
            ? {
                bg: [null, null, null, 'background.primary'],
                color: 'primary',
              }
            : {
                bg: [null, null, null, 'primary'],
                color: 'white',
                '&:hover:not(:disabled)': {
                  bg: [null, null, null, 'background.primary'],
                  color: 'primary',
                },
              }),
        }}
        onClick={togglePopover}
      >
        <Text.inline
          whiteSpace="nowrap"
          display={['none', null, null, 'initial']}
        >
          Mitt konto
        </Text.inline>
        <Text.inline display={[null, null, null, 'none']}>
          <Account title="Mitt konto" />
        </Text.inline>
      </Button>
    </>
  ),
);

export default LoggedInUser;
