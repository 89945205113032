import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as ExternalIcon } from './external.svg';

const External = createLocalStyledIcon(ExternalIcon);

External.defaultProps = {
  width: 15,
  height: 'auto',
};

export default External;
