import {
  Anchor,
  Box,
  Divider,
  List,
  Position,
  Stack,
  Text,
} from '@trmediaab/zebra';
import { useSafeLayoutEffect, useWindowSize } from '@trmediaab/zebra-hooks';
import { createLocalStyledIcon } from '@trmediaab/zebra-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import Facebook from '~/icons/Facebook';
import Instagram from '~/icons/Instagram';
import Twitter from '~/icons/Twitter';
import Youtube from '~/icons/Youtube';
import { isAbsoluteUrl } from '~/utils/url';

import ExternalLink from '../ExternalLink';
import BurgerButton from './BurgerButton';
import { navIconMap, primaryNavItems, secondaryNavItems } from './constants';
import { useSiteNavContext } from './SiteNavContext';

interface PrimaryNavButtonProps extends React.ComponentProps<typeof Anchor> {
  label: string;
  Icon: ReturnType<typeof createLocalStyledIcon>;
  active: boolean;
}

type SecondaryNavButtonProps = Omit<PrimaryNavButtonProps, 'Icon'>;

const PrimaryNavButton = forwardRef<HTMLAnchorElement, PrimaryNavButtonProps>(
  ({ label, Icon, active, ...props }, ref) => (
    <Anchor
      ref={ref}
      variant="none"
      display="flex"
      alignItems="center"
      height={['50px', null, '80px']}
      color={active ? 'link.navActive' : 'link.nav'}
      textDecoration="none"
      textTransform="uppercase"
      pl={[3, null, 4]}
      pr={[null, null, 4]}
      mr={['4,5', null, 0]}
      sx={{
        position: 'relative',
        '&:hover, &:focus': {
          bg: [null, null, 'darkestBlue'],
          color: ['link.navActive', null, 'inherit'],
        },
        '&::after': {
          content: "''",
          position: 'absolute',
          ml: ['4,5', null, 0],
          left: 0,
          right: 0,
          bottom: 0,
          height: '1px',
          width: ['auto', null, '100%'],
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'darkestBlue',
        },
      }}
      {...props}
    >
      <Box width={['32px', null, '35px']}>
        <Text width="18px" textAlign="center" lineHeight="0">
          <Icon
            fillOpacity={active ? 1 : 0.5}
            strokeOpacity={active ? 1 : 0.5}
          />
        </Text>
      </Box>

      <Text fontFamily="secondary" fontSize="3" fontWeight="bold">
        {label}
      </Text>
    </Anchor>
  ),
);

const SecondaryNavButton = forwardRef<
  HTMLAnchorElement,
  SecondaryNavButtonProps
>(({ label, active, ...props }, ref) => (
  <Anchor
    ref={ref}
    variant="none"
    color={active ? 'link.navActive' : 'link.nav'}
    textDecoration="none"
    mx={['4,5', null, 4]}
    sx={{
      '&:hover, &:focus': {
        color: ['link.navActive', null, 'link.active'],
      },
    }}
    {...props}
  >
    <Text.inline fontFamily="secondary" fontSize="2" fontWeight="bold">
      {label}
    </Text.inline>
  </Anchor>
));

const SiteNav = forwardRef<HTMLDivElement>((_, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [navOpen] = useSiteNavContext();
  const { height: windowheight } = useWindowSize() || { height: 0 };
  const [contentHeight, setContentHeight] = useState(Number.MAX_VALUE);
  const router = useRouter();
  const theme = useTheme();
  const navWidth = theme.sizes.siteNav as number;

  useSafeLayoutEffect(() => {
    if (contentRef.current != null) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  return (
    <Position.relative
      ref={ref}
      css={`
        min-height: 100vh;
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      `}
      width={[null, null, null, 'siteNav']}
    >
      <Position
        position={[
          'fixed',
          null,
          null,
          contentHeight <= windowheight ? 'fixed' : 'static',
        ]}
        zIndex="siteNav"
        top="0"
        bg="darkBlue"
        color="white"
        width={['100%', null, 'siteNav']}
        left={navOpen ? 0 : ['-100%', null, `${navWidth * -1}px`, 0]}
        height="100%"
        sx={{
          boxShadow: [null, null, '0px 4px 10px rgba(0, 0, 0, 0.25)'],
          transition: ['left 0.3s', null, null, 'initial'],
          willChange: ['left', null, null, 'initial'],
          overflowY: ['auto', null, 'initial'],
        }}
      >
        <Position.absolute
          right="36px"
          top="25px"
          zIndex="1"
          display={[null, null, 'none']}
        >
          <BurgerButton color="greys.2" />
        </Position.absolute>

        <Stack
          ref={contentRef}
          between={[3, null, '3,5']}
          pt={['10px', null, 0]}
          pb="4"
        >
          <List>
            {primaryNavItems.map(([key, label, href]) => (
              <List.listItem
                key={key}
                css={`
                  position: relative;
                `}
              >
                <Link href={href} passHref>
                  <PrimaryNavButton
                    label={label}
                    Icon={navIconMap[key]}
                    active={href !== '/' && router.asPath.startsWith(href)}
                  />
                </Link>
              </List.listItem>
            ))}
          </List>

          <List gap="2">
            {secondaryNavItems.map(([key, label, href]) => (
              <List.listItem key={key}>
                {isAbsoluteUrl(href) ? (
                  <SecondaryNavButton
                    label={label}
                    href={href}
                    target="_blank"
                    rel="noopener"
                  />
                ) : (
                  <Link href={href} passHref>
                    <SecondaryNavButton
                      label={label}
                      active={href !== '/' && router.asPath.startsWith(href)}
                    />
                  </Link>
                )}
              </List.listItem>
            ))}
          </List>

          <Divider variant="nav" mx={['4,5', null, 0]} />

          <List axis="horizontal" gap="3" mx={['4,5', null, '4']}>
            <List.listItem>
              <ExternalLink
                href="https://www.facebook.com/spelvarde.se"
                color="inherit"
                aria-label="Spelvärde på Facebook"
              >
                <Facebook />
              </ExternalLink>
            </List.listItem>
            <List.listItem>
              <ExternalLink
                href="https://twitter.com/spelvarde_se"
                color="inherit"
                aria-label="Spelvärde på Twitter"
              >
                <Twitter />
              </ExternalLink>
            </List.listItem>
            <List.listItem>
              <ExternalLink
                href="https://www.instagram.com/spelvarde.se"
                color="inherit"
                aria-label="Spelvärde på Instagram"
              >
                <Instagram />
              </ExternalLink>
            </List.listItem>
            <List.listItem>
              <ExternalLink
                href="https://www.youtube.com/channel/UCali_2MMFHlCsSJ0PkQFEtQ"
                color="inherit"
                aria-label="Spelvärde på Youtube"
              >
                <Youtube />
              </ExternalLink>
            </List.listItem>
          </List>
        </Stack>
      </Position>
    </Position.relative>
  );
});

export default SiteNav;
