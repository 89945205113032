import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import Ball from '~/icons/Ball';
import Calendar from '~/icons/Calendar';
import Cart from '~/icons/Cart';
import Flag from '~/icons/Flag';
import Group from '~/icons/Group';
import Home from '~/icons/Home';
import Play from '~/icons/Play';
import School from '~/icons/School';
import Stats from '~/icons/Stats';
import Track from '~/icons/Track';

type NavItemKey = string;
type NavItemLabel = string;
type NavItemHref = string;

type NavItem = [NavItemKey, NavItemLabel, NavItemHref];

export const primaryNavItems: NavItem[] = [
  ['home', 'Hem', '/'],
  ['tips', 'Aktuella tips', '/tips'],
  ['shop', 'Handla', '/handla'],
  ['podtv', 'Podd & TV', '/podd-tv'],
  ['shareBets', 'Andelsspel', '/andelsspel'],
  ['school', 'Spelskola', '/spelskola'],
  ['tracks', 'Banfakta', '/banfakta'],
  ['v75calendar', 'V75-kalender', '/v75-kalender'],
];

export const secondaryNavItems: NavItem[] = [
  ['about', 'Om Spelvärde', '/om-spelvarde'], // TODO - slug should be change to 'om'
  ['help', 'Kundtjänst', '/hjalp'],
  ['privacy', 'Integritetspolicy', 'https://www.trmedia.se/integritetspolicy'],
];

export const navIconMap: Record<
  string,
  ReturnType<typeof createLocalStyledIcon>
> = {
  home: Home,
  tips: Flag,
  shop: Cart,
  podtv: Play,
  statistics: Stats,
  shareBets: Group,
  school: School,
  tracks: Track,
  v75calendar: Calendar,
  sportTips: Ball,
};
