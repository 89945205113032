import { createContext, useContext } from 'react';

type ContextState = readonly [boolean, () => void];

const SiteNavContext = createContext<ContextState>([false, () => null]);

export const SiteNavContextProvider = SiteNavContext.Provider;

export const useSiteNavContext = () => {
  const context = useContext(SiteNavContext);
  if (context == null) {
    throw new Error(
      'useSiteNavContext must be used within SiteNavContext.Provider',
    );
  }
  return context;
};

export default SiteNavContext;
