import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as TwitterIcon } from './twitter.svg';

const Twitter = createLocalStyledIcon(TwitterIcon, 'Twitter');

Twitter.defaultProps = {
  width: 20,
  height: 'auto',
};

export default Twitter;
