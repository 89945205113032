import { Button, Stack } from '@trmediaab/zebra';

import useAuthUrl from '~/hooks/useAuthUrl';
import useRegisterUrl from '~/hooks/useRegisterUrl';
import Login from '~/icons/Login';

const LoggedOutUser = () => {
  const registerUrl = useRegisterUrl();
  const authUrl = useAuthUrl();

  return (
    <Stack axis="horizontal" between="3">
      <Button
        display={['none', null, 'inline-flex']}
        shape="square"
        size="siteHeader"
        colorScheme="tertiary"
        px="3"
        href={registerUrl}
      >
        Skapa konto
      </Button>
      <Button
        display={['none', null, 'inline-flex']}
        shape="square"
        size="siteHeader"
        sx={{
          bg: 'primary',
          color: 'white',
          '&:hover:not(:disabled)': {
            bg: 'primaryDarkened',
          },
        }}
        px={[null, null, 3, '3,5']}
        href={authUrl}
      >
        Logga in
      </Button>
      <Button
        display={['inline-flex', null, 'none']}
        shape="square"
        size="siteHeader"
        color="white"
        sx={{
          '&:hover:not(:disabled)': {
            color: 'primary',
          },
        }}
        href={authUrl}
      >
        <Login title="Logga in" />
      </Button>
    </Stack>
  );
};

export default LoggedOutUser;
