import { useRouter } from 'next/router';

import { selectOrigin } from '~/main/config';

import { useAppSelector } from './useRedux';

export default function useRegisterUrl(returnToCurrentPage = true) {
  const baseUrl = useAppSelector(selectOrigin);
  const { asPath } = useRouter();

  const qs = `?ReturnUrl=${encodeURI(
    baseUrl + (returnToCurrentPage ? asPath : ''),
  )}`;

  return `${process.env.NEXT_PUBLIC_AUTH_URL}/register${qs}`;
}
