import { BetType } from '~/types/models/BetType';
import { Round } from '~/types/models/round/Round';
import { RoundLegRaceMeet } from '~/types/models/round/RoundLeg';

import { betTypeDomesticTexts, isSingleRaceBetType } from './betTypeUtils';

export function isAbsoluteUrl(url: string) {
  return /^https?:\/\/[^/]+/.test(url);
}

export function createRoundUrl(slug: string, subPath?: string) {
  return `/tips/${slug.toLowerCase().replace('-', '/')}${
    subPath != null ? `/${subPath.replace(/^\/+/, '')}` : ''
  }`;
}

export function createAtgRoundUrl(round: Round, leg?: number): string;
export function createAtgRoundUrl(
  meet: RoundLegRaceMeet,
  race: number,
  betType: BetType,
): string;
export function createAtgRoundUrl(
  roundOrMeet: Round | RoundLegRaceMeet,
  legOrRace?: number,
  betType?: BetType,
): string {
  let selectedBetType;
  let trackSlug;
  let date;

  if (roundOrMeet == null || typeof roundOrMeet != 'object') {
    throw new Error('Missing round or meet object');
  }

  if ('bet_type' in roundOrMeet) {
    selectedBetType = roundOrMeet.bet_type;
    trackSlug = roundOrMeet.meets.map(meet => meet.track_slug).join('-');
    date = roundOrMeet.round_date;
  } else if (betType == null) {
    throw new Error('A bet type must be provided');
  } else {
    selectedBetType = betType;
    trackSlug = roundOrMeet.track_slug;
    date = roundOrMeet.race_date;
  }

  return `https://www.atg.se/spel/${date}/${betTypeDomesticTexts[
    selectedBetType
  ].toLowerCase()}/${trackSlug}${
    legOrRace != null
      ? `/${isSingleRaceBetType(selectedBetType) ? 'lopp' : 'avd'}${legOrRace}`
      : ''
  }`;
}
