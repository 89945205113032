import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as GroupIcon } from './group.svg';

const Group = createLocalStyledIcon(GroupIcon);

Group.defaultProps = {
  width: 15,
  height: 'auto',
};

export default Group;
