import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as CalendarIcon } from './calendar.svg';

const Calendar = createLocalStyledIcon(CalendarIcon);

Calendar.defaultProps = {
  width: 14,
  height: 'auto',
};

export default Calendar;
