import { useClickOutside, useWindowEvents } from '@trmediaab/zebra-hooks';
import { canUseDOM } from 'exenv';
import { useCallback, useRef, useState } from 'react';

import useRouterEvents from './useRouterEvents';

export default function usePopover(): [
  boolean,
  () => void,
  React.RefObject<HTMLDivElement>,
  React.RefObject<HTMLButtonElement>,
  DOMRect | null,
] {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [buttonCoords, setButtonCoords] = useState<DOMRect | null>(
    canUseDOM ? new DOMRect(0, 0, 0, 0) : null,
  );
  const popoverRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);

  const resizeHandler = useCallback(() => {
    if (popoverVisible && toggleButtonRef.current) {
      setButtonCoords(toggleButtonRef.current.getBoundingClientRect());
    }
  }, [popoverVisible]);

  const closePopover = useCallback(() => {
    if (popoverVisible) {
      setPopoverVisible(false);
    }
  }, [popoverVisible]);

  const togglePopover = useCallback(() => {
    if (popoverVisible) {
      setPopoverVisible(false);
    } else {
      if (toggleButtonRef.current != null) {
        setButtonCoords(toggleButtonRef.current.getBoundingClientRect());
      }
      setPopoverVisible(true);
    }
  }, [popoverVisible]);

  useRouterEvents({ onComplete: closePopover });
  useClickOutside([popoverRef, toggleButtonRef], closePopover);
  useWindowEvents('resize', resizeHandler);

  return [
    popoverVisible,
    togglePopover,
    popoverRef,
    toggleButtonRef,
    buttonCoords,
  ];
}
