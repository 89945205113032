import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as AccountIcon } from './account.svg';

const Account = createLocalStyledIcon(AccountIcon);

Account.defaultProps = {
  width: 20,
  height: 'auto',
};

export default Account;
