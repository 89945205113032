import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as TrackIcon } from './track.svg';

const Track = createLocalStyledIcon(TrackIcon);

Track.defaultProps = {
  width: 19,
  height: 'auto',
};

export default Track;
