// TODO - this could live in Zebra

import { BetType } from '~/types/models/BetType';

export const atgBetTypes: BetType[] = [
  'GS75',
  'DD',
  'P',
  'LD',
  'T',
  'T7',
  'TV',
  'V',
  'V3',
  'V4',
  'V5',
  'V64',
  'V65',
  'V75',
  'V86',
];

export const betTypeDomesticTexts: { [betType in BetType]: string } = {
  GS75: 'GS75',
  DD: 'Dagens Dubbel',
  P: 'Plats',
  LD: 'Lunch Dubbel',
  T: 'Trio',
  T7: 'Top 7',
  TV: 'Tvilling',
  V: 'Vinnare',
  V3: 'V3',
  V4: 'V4',
  V5: 'V5',
  V64: 'V64',
  V65: 'V65',
  V75: 'V75',
  V86: 'V86',
};

export function isSingleRaceBetType(betType: BetType) {
  const singleRaceBetTypes: Partial<BetType[]> = ['V', 'P', 'TV', 'T', 'T7'];
  return singleRaceBetTypes.includes(betType);
}
