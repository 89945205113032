import {
  Anchor,
  BorderBox,
  Box,
  Divider,
  Heading,
  Position,
  Stack,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';
import { forwardRef, useDeferredValue } from 'react';

import useAuthUrl from '~/hooks/useAuthUrl';

import ExternalLink from '../ExternalLink';

interface LoggedInUserPopoverProps {
  buttonCoords: DOMRect;
}

const LoggedInUserPopover = forwardRef<
  HTMLDivElement,
  LoggedInUserPopoverProps
>(({ buttonCoords }, ref) => {
  const authUrl = useAuthUrl();
  const arrowSize = 14;
  const deferredCoords = useDeferredValue(buttonCoords);
  const top = deferredCoords.top + deferredCoords.height + 7;

  const mobile = {
    arrowOffsetLeft:
      deferredCoords.left + deferredCoords.width / 2 - arrowSize / 2,
    left: 0,
    width: '100%',
  };

  const desktop = {
    arrowOffset: 70,
    width: 420,
    left:
      deferredCoords.left + deferredCoords.width / 2 - 420 + 70 + arrowSize / 2,
  };

  return (
    <Position.fixed
      ref={ref}
      zIndex="siteNav"
      sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);' }}
      top={top}
      left={[mobile.left, null, null, desktop.left]}
      width={[mobile.width, null, null, desktop.width]}
    >
      <BorderBox
        /* display={['none', null, null, 'block']} */
        width="0"
        height="0"
        borderLeft={`${arrowSize / 2}px`}
        borderRight={`${arrowSize / 2}px`}
        borderBottom={`${arrowSize / 2}px`}
        borderLeftColor="transparent"
        borderRightColor="transparent"
        borderBottomColor="bgGrey98"
        ml={[mobile.arrowOffsetLeft, null, null, 'auto']}
        mr={[null, null, null, desktop.arrowOffset]}
      />
      <BorderBox
        borderTopLeftRadius={[null, null, null, 2]}
        borderTopRightRadius={[null, null, null, 2]}
        borderBottomLeftRadius="2"
        borderBottomRightRadius="2"
        bg="bgGrey98"
        px="3,5"
        pb="3,5"
        pt={[4, null, null, '3,5']}
        width="100%"
      >
        <Stack between="3">
          {
            // eslint-disable-next-line no-constant-condition
            false /* TODO - fetch latest purchases from trhub-api */ ? (
              <Stack between="2">
                <Heading.h4 fontWeight="medium" color="greys.3">
                  Senaste köp
                </Heading.h4>
                <Text.em display="block">TODO</Text.em>
              </Stack>
            ) : (
              <BorderBox
                border="1"
                borderRadius="1"
                bg="white"
                p={[3, null, '3,5']}
                css={`
                  text-align: center;
                `}
              >
                <Text.p mb="2" color="text.faded">
                  Här visas dina senast köpta tips
                </Text.p>
                <Link href="/tips" passHref>
                  <Anchor textDecoration="underline">Aktuella tips</Anchor>
                </Link>
              </BorderBox>
            )
          }
          <Divider />
          <Box>
            <ExternalLink
              href={process.env.NEXT_PUBLIC_USERPAGES_URL}
              icon
              variant="matchText"
              fontWeight="bold"
            >
              Mitt TR Media-konto
            </ExternalLink>
          </Box>
          <Divider />
          <Box>
            <ExternalLink
              href={authUrl}
              newTab={false}
              variant="matchText"
              fontWeight="bold"
            >
              Logga ut
            </ExternalLink>
          </Box>
        </Stack>
      </BorderBox>
    </Position.fixed>
  );
});

export default LoggedInUserPopover;
