import { Box, Container, FlexBox, Position } from '@trmediaab/zebra';
import { useTheme } from 'styled-components';

import BurgerButton from '~/components/SiteNav/BurgerButton';
import { useSiteNavContext } from '~/components/SiteNav/SiteNavContext';
import usePopover from '~/hooks/usePopover';
import useUser from '~/hooks/useUser';

import Logo from '../Logo';
import {
  SITE_HEADER_HEIGHT_DESKTOP,
  SITE_HEADER_HEIGHT_MOBILE,
} from './constants';
import LoggedInUser from './LoggedInUser';
import LoggedInUserPopover from './LoggedInUserPopover';
import LoggedOutUser from './LoggedOutUser';

interface SiteHeaderProps {
  burgerButtonRef: Nullable<React.Ref<HTMLButtonElement>>;
}

const SiteHeader = ({ burgerButtonRef }: SiteHeaderProps) => {
  const [navOpen] = useSiteNavContext();
  const theme = useTheme();
  const [
    loggedInPopoverVisible,
    toggleLoggedInPopover,
    loggedInPopoverRef,
    loggedInToggleButtonRef,
    buttonCoords,
  ] = usePopover();
  const navWidth = theme.sizes.siteNav as number;
  const [user, error] = useUser();

  return (
    <>
      <Position.sticky
        as="header"
        bg="background.dark"
        top="0"
        zIndex="siteHeader"
      >
        <Container
          height={[
            SITE_HEADER_HEIGHT_MOBILE,
            null,
            null,
            SITE_HEADER_HEIGHT_DESKTOP,
          ]}
          display="flex"
          alignItems="center"
        >
          <FlexBox
            flex={[1, null, null, 'initial']}
            display={['block', null, null, 'none']}
          >
            <Position.relative
              ml={[null, null, navOpen ? `${navWidth}px` : 0, 0]}
              sx={{ transition: 'margin-left 0.3s', willChange: 'margin-left' }}
            >
              <BurgerButton ref={burgerButtonRef} />
            </Position.relative>
          </FlexBox>
          <FlexBox
            flex={[2, null, null, 'initial']}
            justifyContent={['center', null, null, 'initial']}
          >
            <Logo title="Spelvärde" height={['26px', null, null, '34px']} />
          </FlexBox>
          <Box
            flex={[1, null, null, 'initial']}
            ml={[null, null, null, 'auto']}
          >
            <FlexBox justifyContent="flex-end">
              {user != null && (
                <LoggedInUser
                  ref={loggedInToggleButtonRef}
                  popoverVisible={loggedInPopoverVisible}
                  togglePopover={toggleLoggedInPopover}
                />
              )}
              {user == null && error != null && <LoggedOutUser />}
            </FlexBox>
          </Box>
        </Container>
      </Position.sticky>
      {user != null && loggedInPopoverVisible && (
        <LoggedInUserPopover
          ref={loggedInPopoverRef}
          buttonCoords={buttonCoords as DOMRect}
        />
      )}
    </>
  );
};

export default SiteHeader;
