import { Box, Container, Stack } from '@trmediaab/zebra';

import { DEFAULT_SPACE_TOP_BIG, DEFAULT_SPACE_TOP_SMALL } from '~/utils/styles';

import {
  SITE_HEADER_HEIGHT_DESKTOP,
  SITE_HEADER_HEIGHT_MOBILE,
} from '../SiteHeader/constants';

interface PageLayoutProps extends React.ComponentProps<typeof Container> {
  top?: React.ReactNode;
  main?: React.ReactNode;
  mainSingle?: React.ReactNode;
  aside?: React.ReactNode;
  rounds?: React.ReactNode;
  topVariant?: 'oneColumn' | 'twoColumn';
  asideWidth?: 'default' | 'narrow';
  stickyAside?: boolean;
}

interface AreaProps extends React.ComponentProps<typeof Box> {
  children: React.ReactNode;
}

const toTemplateAreas = (areas: string[]) =>
  areas.map(area => `"${area}"`).join(' ');

// TBD
export const ASIDE_MIN_WIDTH_SMALL = '260px';
export const ASIDE_MIN_WIDTH_MEDIUM = '330px';
export const ASIDE_MIN_WIDTH_LARGE = '362px';

const TOP_SPACE = [DEFAULT_SPACE_TOP_SMALL, null, DEFAULT_SPACE_TOP_BIG];
const TOP_SPACE_TWO_COL_ONLY = [null, null, DEFAULT_SPACE_TOP_BIG];
const STACK_SPACE = [4, null, '4,5'];
const STACK_SPACE_ONE_COL_ONLY = [4, null, 0];

const PageLayout = ({
  top,
  main,
  mainSingle,
  aside,
  rounds,
  stickyAside = false,
  topVariant = 'twoColumn',
  asideWidth = 'default',
  ...props
}: PageLayoutProps) => {
  const mobileAreas = [
    rounds != null && 'mobileRounds',
    top != null && 'top',
    main != null && 'main',
    aside != null && 'aside',
    mainSingle != null && 'mainSingle',
  ].filter(Boolean);

  const desktopAreas = [
    top != null && topVariant === 'oneColumn' ? 'top _' : 'top top',
    (main != null || rounds != null) && 'main desktopRounds',
    (main != null || aside != null) && 'main aside',
    mainSingle != null && 'mainSingle mainSingle',
  ].filter(Boolean);

  if (stickyAside && rounds != null) {
    throw new Error('Aside cannot be sticky with rounds present');
  }

  const gridTemplateColumns = ['1fr', null, `auto ${ASIDE_MIN_WIDTH_SMALL}`];

  if (asideWidth === 'default') {
    gridTemplateColumns.push(
      null,
      `auto ${ASIDE_MIN_WIDTH_MEDIUM}`,
      `auto ${ASIDE_MIN_WIDTH_LARGE}`,
    );
  }

  return (
    <Container
      {...props}
      display="grid"
      alignItems="start"
      sx={{
        columnGap: [null, null, 5, '3,5', 5, 6],
        gridTemplateColumns,
        gridTemplateRows: [
          'auto',
          null,
          desktopAreas.length <= 2 ? 'auto 1fr' : 'auto auto 1fr auto',
        ],
        gridTemplateAreas: [
          toTemplateAreas(mobileAreas),
          null,
          toTemplateAreas(desktopAreas),
        ],
      }}
    >
      {top && (
        <AreaTop mt={TOP_SPACE} mb={[3, null, '4,5']}>
          {top}
        </AreaTop>
      )}
      {rounds && (
        <AreaRounds mt={top == null ? TOP_SPACE_TWO_COL_ONLY : undefined}>
          {rounds}
        </AreaRounds>
      )}
      {main && (
        <AreaMain area="main" mt={top == null ? TOP_SPACE : undefined}>
          {main}
        </AreaMain>
      )}
      {aside && (
        <AreaAside
          mt={
            top == null && rounds == null
              ? TOP_SPACE
              : rounds != null
              ? STACK_SPACE
              : STACK_SPACE_ONE_COL_ONLY
          }
          sticky={stickyAside && rounds == null}
        >
          {aside}
        </AreaAside>
      )}
      {mainSingle && (
        <AreaMain
          mt={top == null || main != null ? TOP_SPACE : undefined}
          area="mainSingle"
        >
          {mainSingle}
        </AreaMain>
      )}
    </Container>
  );
};

const AreaMain = ({
  children,
  area,
  ...props
}: AreaProps & { area: 'main' | 'mainSingle' }) => (
  <Box sx={{ gridArea: area }} {...props}>
    {children}
  </Box>
);

const AreaAside = ({
  children,
  sticky,
  ...props
}: AreaProps & { sticky: boolean }) => (
  <Stack
    sx={{
      gridArea: 'aside',
      position: sticky ? [null, null, 'sticky'] : undefined,
      top: sticky
        ? [
            null,
            null,
            SITE_HEADER_HEIGHT_MOBILE + 8,
            SITE_HEADER_HEIGHT_DESKTOP + 16,
          ]
        : undefined,
    }}
    between={STACK_SPACE}
    {...props}
  >
    {children}
  </Stack>
);

const AreaTop = ({ children, ...props }: AreaProps) => (
  <Box sx={{ gridArea: 'top' }} {...props}>
    {children}
  </Box>
);

const AreaRounds = ({ children, ...props }: AreaProps) => (
  <Box sx={{ gridArea: ['mobileRounds', null, 'desktopRounds'] }} {...props}>
    {children}
  </Box>
);

export default PageLayout;
