import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as StatsIcon } from './stats.svg';

const Stats = createLocalStyledIcon(StatsIcon);

Stats.defaultProps = {
  width: 14,
  height: 'auto',
};

export default Stats;
