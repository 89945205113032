import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as YoutubeIcon } from './youtube.svg';

const Youtube = createLocalStyledIcon(YoutubeIcon, 'Youtube');

Youtube.defaultProps = {
  width: 20,
  height: 'auto',
};

export default Youtube;
