import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as HomeIcon } from './home.svg';

const Home = createLocalStyledIcon(HomeIcon);

Home.defaultProps = {
  width: 18,
  height: 'auto',
};

export default Home;
