import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as SchoolIcon } from './school.svg';

const School = createLocalStyledIcon(SchoolIcon);

School.defaultProps = {
  width: 19,
  height: 'auto',
};

export default School;
