import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as FlagIcon } from './flag.svg';

const Flag = createLocalStyledIcon(FlagIcon);

Flag.defaultProps = {
  width: 14,
  height: 'auto',
};

export default Flag;
