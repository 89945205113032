import { useQueryState } from '~/services/auth';
import { SpelvardeUserRole } from '~/types/models/User';

const useUser = () => {
  const { data, error, isLoading, isFetching } = useQueryState();

  return [data, error, isLoading || isFetching] as const;
};

export const useHasRole = (
  roleOrRoles: SpelvardeUserRole | SpelvardeUserRole[],
) => {
  const [user] = useUser();

  const arr = Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles];

  return user?.groups.spelvarde?.some(role => arr.includes(role));
};

export default useUser;
