import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as FacebookIcon } from './facebook.svg';

const Facebook = createLocalStyledIcon(FacebookIcon, 'Facebook');

Facebook.defaultProps = {
  width: 20,
  height: 'auto',
};

export default Facebook;
