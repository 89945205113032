import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as PlayIcon } from './play.svg';

const Play = createLocalStyledIcon(PlayIcon);

Play.defaultProps = {
  width: 12,
  height: 'auto',
};

export default Play;
