import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as LoginIcon } from './login.svg';

const Login = createLocalStyledIcon(LoginIcon);

Login.defaultProps = {
  width: 23,
  height: 'auto',
};

export default Login;
