import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as BallIcon } from './ball.svg';

const Ball = createLocalStyledIcon(BallIcon);

Ball.defaultProps = {
  width: 15,
  height: 'auto',
};

export default Ball;
